:root {
  --sitecolor: #fcc353;
  --textcolor: #022142;
  --titlecolor: #022142;
  --bgcolor-pri: #ffffff;
  --bgcolor-sec: #eee6dc;
  --linkcolor: #022142;
  --linkcolor-hov: #fcc353;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  cursor: pointer;
  padding: 15px 32px 15px 72px;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  color: #022142;
  border-radius: 100px;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: 'PPNeueMachina', sans-serif;
  font-weight: 800;
  isolation: isolate;
  position: relative;
  border: 1px solid #022142;
  background-color: var(--btn-bgcolor, var(--sitecolor));
}
.button:before {
  content: '';
  left: -1px;
  top: -1px;
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid #022142;
  box-sizing: border-box;
  background-color: #fd5b02;
  background-size: 31px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-darkblue.svg);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (hover: hover) and (pointer: fine) {
  body:not(#edit) .button:hover,
  body:not(#edit) .button:focus {
    padding: 15px 72px 15px 32px;
  }
  body:not(#edit) .button:hover:before,
  body:not(#edit) .button:focus:before {
    content: '';
    left: calc(100% - 47px);
  }
}
body:not(#edit) .button-hover {
  padding: 15px 72px 15px 32px;
}
body:not(#edit) .button-hover:before {
  content: '';
  left: calc(100% - 47px);
}
.button-simple {
  cursor: pointer;
  padding: 13px 32px 13px 32px;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  color: #022142;
  border-radius: 100px;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: 'PPNeueMachina', sans-serif;
  font-weight: 800;
  isolation: isolate;
  position: relative;
  background-color: #fd5b02;
  border: 1px solid #022142;
}
.button-sec {
  cursor: pointer;
  padding: 5px 0 3px 32px;
  appearance: none;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  color: var(--textcolor);
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: 'PPNeueMachina', sans-serif;
  font-weight: 800;
  isolation: isolate;
  position: relative;
  min-height: 24px;
}
.button-sec:before {
  content: '';
  left: 0;
  top: calc(50% - 12px);
  position: absolute;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  mask-size: 24px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-darkblue.svg);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  background-color: var(--textcolor);
}
@media (hover: hover) and (pointer: fine) {
  body:not(#edit) .button-sec:hover,
  body:not(#edit) .button-sec:focus {
    padding: 5px 32px 3px 0;
  }
  body:not(#edit) .button-sec:hover:before,
  body:not(#edit) .button-sec:focus:before {
    content: '';
    left: calc(100% - 24px);
  }
}
body:not(#edit) .button-sec-hover {
  padding: 5px 32px 3px 0;
}
body:not(#edit) .button-sec-hover:before {
  content: '';
  left: calc(100% - 24px);
}
.footarea {
  grid-template-columns: repeat(6, 1fr);
  gap: 80px;
}
.footpart {
  grid-column: span 6;
}
.footpart--slim {
  grid-column: span 3;
}
@media (max-width: 1023px) {
  .footpart--half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 80px;
    justify-content: space-between;
  }
}
.footpart--wide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}
.foottitle {
  margin-bottom: 8px;
}
.p4b {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.area--one {
  grid-template-columns: repeat(6, 1fr);
  gap: 0 40px;
}
.area--one .unit {
  grid-column: span 6;
}
.area--one .unitThree {
  grid-column: span 4;
}
@media (max-width: 1023px) {
  .unitThree:nth-child(2n+2 of .unitThree) {
    margin-left: clamp(14.063rem, -1.875rem + 33.2vw, 19.375rem);
  }
}
.area--one .unitSix {
  display: grid;
  gap: 40px;
  justify-content: end;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1023px) {
  .area--one .unitSix:not(.unit__hasBackground) .unit__content {
    width: auto;
    grid-column: 1 / 7;
  }
  .area--one .unitSix.unit--hasBackground .unit__background {
    grid-column: 1 / 3;
  }
  .area--one .unitSix.unit--hasBackground .unit__background img {
    width: 143px;
    aspect-ratio: 1;
  }
  .area--one .unitSix.unit--hasBackground .unit__content {
    width: auto;
    grid-column: 3 / 7;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */